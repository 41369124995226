// React components
import React, { Component, useEffect, useRef } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

// API components
import Axios from "axios";

// Material-UI components
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import LinearProgress from "@mui/material/LinearProgress";
import Drawer from "@mui/material/Drawer";

import UserIcon from "@mui/icons-material/AccountCircle";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import NextIcon from "@mui/icons-material/NavigateNext";
import BackIcon from "@mui/icons-material/NavigateBefore";
import AgreeIcon from "@mui/icons-material/CheckCircle";
import PreviewIcon from "@mui/icons-material/Visibility";
import CodebookIcon from "@mui/icons-material/Article";
import ResetIcon from "@mui/icons-material/RestartAlt";
import SelectIcon from "@mui/icons-material/SelectAll";
import DeselectIcon from "@mui/icons-material/Deselect";
import CancelIcon from "@mui/icons-material/Cancel";
import StartIcon from '@mui/icons-material/Start';
import RestartIcon from "@mui/icons-material/RestartAlt";

// components
import DataTableComponent from "./DataTableComponent";
import CitationComponent from "./CitationComponent";
import CodebookComponent from "./CodebookComponent";
import { scroll }  from "./Utilities";
import { trackAction } from "./Utilities";

export default function DownloadToolComponent (props) {

  var { token, user, APIURL } = props;

  //--------------------------------------------------
  // state variables
  //--------------------------------------------------

  const [activeStep, setActiveStep] = React.useState(-1);
  const [dataset, setDataset] = React.useState("decisions");
  const [version, setVersion] = React.useState("v-2023-1");
  const [selectedVariables, setSelectedVariables] = React.useState([]);
  const [selectedFilters, setSelectedFilters] = React.useState([]);
  const [selectedFilterValues, setSelectedFilterValues] = React.useState([]);

  const [codebookDialogOpen, setCodebookDialogOpen] = React.useState(false);
  const [previewDialogOpen, setPreviewDialogOpen] = React.useState(false);

  const [data, setData] = React.useState(null);
  const [observations, setObservations] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const headers = {
    "Content-Type": "application/json",
    "authorization": token,
  }

  const updateSelectedFilters = (variable, value) => {
    var listFilters = [...selectedFilters];
    var listValues = [...selectedFilterValues];
    if (listFilters.includes(variable)) {
      const index = listFilters.indexOf(variable)
      if (index > -1) {
        if (value == "") {
          listFilters.splice(index, 1);
          listValues.splice(index, 1);
          setSelectedFilters(listFilters);
          setSelectedFilterValues(listValues);
        } else {
          listValues[index] = value;
          setSelectedFilterValues(listValues);
        }
      }
    } else {
      setSelectedFilters([...listFilters, variable]);
      setSelectedFilterValues([...listValues, value]);
    }
  }

  const getFilterValue = (variable) => {
    var listFilters = [...selectedFilters];
    var listValues = [...selectedFilterValues];
    if (listFilters.includes(variable)) {
      const index = listFilters.indexOf(variable)
      if (index > -1) {
        return listValues[index];
      }
    }
    return "";
  }

  const updateSelectedVariables = (variable) => {
    var list = [...selectedVariables];
    if (list.includes(variable)) {
      const index = list.indexOf(variable)
      if (index > -1) {
        list.splice(index, 1);
        setSelectedVariables(list);
      }
    } else {
      setSelectedVariables([...list, variable]);
    }
  }

  //--------------------------------------------------
  // API methods
  //--------------------------------------------------

  const getData = () => {
    setIsLoaded(false);
    const query = buildQuery() + "&limit=100";
    Axios.get(query, { headers: headers })
    .then(response => {
      trackAction(user, "download_tool_preview", query);
      setData(response.data);
      setIsLoaded(true);
    })
    .catch(error => {
      console.log(error);
    });
  };

  const getObservations = () => {
    const url = buildQuery() + "&count=1";
    Axios.get(url, { headers: headers })
    .then(response => {
      setObservations(response.data[0].observations)
    })
    .catch(error => {
      console.log(error);
    });
  };

  const downloadData = () => {
    setIsDownloading(true);
    const query = buildQuery() + "&download=zip";
    Axios.get(query, { headers: headers })
    .then(response => {
      trackAction(user, "download_tool", query);
      var url = null;
      var file = null;
      if (query.includes("&download=zip")) {
        url = "data:application/zip;base64," + response.data.content;
        file = "UNGA-DM-Database-download-tool.zip";
      }
      if (query.includes("&download=csv")) {
        url = window.URL.createObjectURL(new Blob([response.data]));
        file = "UNGA-DM-Database-download-tool.csv";
      }
      var a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", file);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setIsDownloading(false);
    });
  };

  const buildQuery = () => {
    var query = "";
    var conditions = "";
    for (let i = 0; i < selectedFilters.length; i++) {
      var filterText = "&" + selectedFilters[i] + "=" + selectedFilterValues[i];
      conditions += filterText;
    }
    var variables = "&variables=" + selectedVariables.join(",");
    query = APIURL + "/database/" + dataset + "?" + conditions + variables + "&version=" + version;
    return query;
  };

  //--------------------------------------------------
  // interface methods
  //--------------------------------------------------

  const handleRestartButton = () => {
    setActiveStep(0);
  }

  const handleNextButton = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBackButton = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDownloadButton = () => {
    downloadData();
  };

  const handlePreviewButton = () => {

  };

  const resetFilters = () => {
    setSelectedFilters([]);
    setSelectedFilterValues([]);
    setSelectedVariables(getVariables());
  }

  var listVariables = [];

  const getVariables = () => {

    if (dataset === "member_states") {
      listVariables = ["member_state_id", "member_state", "start_date", "end_date", "membership_status"];
    }

    if (dataset === "sessions") {
      listVariables = ["session_id", "session_type", "start_date", "end_date", "numbering_system"];
    }

    if (dataset === "meeting_records") {
      listVariables = ["session_id", "session_type", "meeting_record_id", "meeting_number", "resumption_number", "meeting_date", "meeting_location"];
    }

    if (dataset === "draft_resolutions") {
      listVariables = ["session_id", "session_type", "draft_resolution_id", "draft_resolution_type"];
    }

    if (dataset === "resolutions") {
      listVariables = ["session_id", "session_type", "meeting_record_id", "meeting_date", "draft_resolution_id", "draft_resolution_type", "resolution_id"];
    }

    if (dataset === "decisions") {
      listVariables = ["session_id", "session_type", "meeting_record_id", "meeting_date", "draft_resolution_id", "draft_resolution_type", "resolution_id", "decision_id", "decision_mode", "decision_topic", "important", "page_number"];
    }

    if (dataset === "outcomes") {
      listVariables = ["session_id", "session_type", "meeting_record_id", "meeting_date", "draft_resolution_id", "draft_resolution_type", "resolution_id", "decision_id", "decision_mode", "decision_topic", "important", "page_number", "count_member_states", "count_in_favor", "count_against", "count_abstaining", "count_not_voting", "successful", "failed_two_thirds_majority"];
    }

    if (dataset === "votes") {
      listVariables = ["session_id", "session_type", "meeting_record_id", "meeting_date", "draft_resolution_id", "draft_resolution_type", "resolution_id", "decision_id", "decision_mode", "decision_topic", "important", "page_number", "member_state_id", "member_state", "original_seat_name", "current_seat_name", "original_vote", "amended_vote", "vote_was_amended"];
    }

    return listVariables;
  }

  const deselectAllVariables = () => {
    setSelectedVariables([]);
  }

  const selectAllVariables = () => {
    setSelectedVariables(getVariables());
  }

  const makeHeader = () => {
    var header = [];
    var variables = getVariables();
    for (let i = 0; i < variables.length; i++) {
      if (selectedVariables.includes(variables[i])) {
        header.push(variables[i]);
      }
    }
    return header;
  }

  //--------------------------------------------------
  // codebook dialog
  //--------------------------------------------------

  // const codebookDialog =
  // <div>
  //   <Drawer
  //     variant="temporary"
  //     anchor="top"
  //     open={codebookDialogOpen}
  //     onClose={() => setCodebookDialogOpen(false)}
  //     PaperProps={{
  //       style: {
  //         "width": "100%",
  //         "height": "90vh"
  //       }
  //     }}
  //     ModalProps={{
  //       keepMounted: true,
  //     }}
  //     BackdropProps={{
  //       style: {
  //         backgroundColor: "rgba(0, 0, 0, 0.3)"
  //       }
  //     }}
  //   >
  //     <div className="dialog__header-container">
  //       UNGA-DM Database Codebook
  //     </div>
  //     <div className="dialog__content-container">
  //       <CodebookComponent/>
  //     </div>
  //     <div className="dialog__footer-container">
  //       <Button variant="outlined" startIcon={<CancelIcon/>} onClick={() => setCodebookDialogOpen(false)}>Close</Button>
  //     </div>
  //   </Drawer>
  // </div>

  const codebookDialog =
  <Dialog
    open={codebookDialogOpen}
    onClose={() => setCodebookDialogOpen(false)}
    BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.3)" }}}
    PaperProps={{ style: { width: "calc(100vw - 100px)", height: "calc(100vh - 100px)" } }}
  >
    <div className="dialog__header-container">
      UNGA-DM Database Codebook
    </div>
    <div className="dialog__content-container">
      <CodebookComponent/>
    </div>
    <div className="dialog__footer-container">
      <Button variant="outlined" startIcon={<CancelIcon/>} onClick={() => setCodebookDialogOpen(false)}>Close</Button>
    </div>
  </Dialog>

  //--------------------------------------------------
  // preview dialog
  //--------------------------------------------------

  const previewDialog =
  <Dialog
    open={previewDialogOpen}
    onClose={() => setPreviewDialogOpen(false)}
    BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.3)" }}}
    PaperProps={{ style: { width: "calc(100vw - 100px)", height: "calc(100vh - 100px)" } }}
  >
    <div className="dialog__header-container">
      Preview your data
    </div>
    <div className="dialog__content-container">
      <div className="data-table__caption">
        Your search returned <span className="bold">{observations}</span> observations. This preview shows the first 100 observations. Check to make sure this is the data you want. You can go back and make any changes and then preview the data again before you download it.
      </div>
      <DataTableComponent data={data} headerData={makeHeader()} width={"100%"} />
    </div>
    <div className="dialog__footer-container">
      <Button variant="outlined" startIcon={<CancelIcon/>} onClick={() => setPreviewDialogOpen(false)}>Close</Button>
    </div>
  </Dialog>

  //--------------------------------------------------
  // select observations form
  //--------------------------------------------------

  const sessionTypeFilter =
  <div className="form__element-container--left">
    <FormControl>
      <InputLabel>Session type</InputLabel>
      <Select
        style={{ width: 300 }}
        variant="filled"
        value={getFilterValue("session_type")}
        IconComponent={SelectArrow}
        onChange={(event) => updateSelectedFilters("session_type", event.target.value)}
      >
        <MenuItem className="italic" value={""}>Any</MenuItem>
        <MenuItem value={"regular session"}>Regular sesson</MenuItem>
        <MenuItem value={"special session"}>Special session</MenuItem>
        <MenuItem value={"emergency special session"}>Emergency special session</MenuItem>
      </Select>
    </FormControl>
  </div>

  const minSessionFilter =
  <div className="form__element-container--left">
    <TextField
      style={{ width: 225 }}
      variant="filled"
      label="Session number (min)"
      value={getFilterValue("session_number_min")}
      onChange={(event) => updateSelectedFilters("session_number_min", event.target.value)}
    />
  </div>

  const maxSessionFilter =
  <div className="form__element-container--left">
    <TextField
      style={{ width: 225 }}
      variant="filled"
      label="Session number (max)"
      value={getFilterValue("session_number_max")}
      onChange={(event) => updateSelectedFilters("session_number_max", event.target.value)}
    />
  </div>

  const minMeetingDateFilter =
  <div className="form__element-container--left">
    <TextField
      style={{ width: 225 }}
      variant="filled"
      label="Meeting date (min)"
      value={getFilterValue("meeting_date_min")}
      helperText="Format: YYYY-MM-DD"
      onChange={(event) => updateSelectedFilters("meeting_date_min", event.target.value)}
    />
  </div>

  const maxMeetingDateFilter =
  <div className="form__element-container--left">
    <TextField
      style={{ width: 225 }}
      variant="filled"
      label="Meeting date (max)"
      value={getFilterValue("meeting_date_max")}
      helperText="Format: YYYY-MM-DD"
      onChange={(event) => updateSelectedFilters("meeting_date_max", event.target.value)}
    />
  </div>

  const draftResolutionTypeFilter =
  <div className="form__element-container--left">
    <FormControl>
      <InputLabel>Draft resolution type</InputLabel>
      <Select
        style={{ width: 300 }}
        variant="filled"
        value={getFilterValue("draft_resolution_type")}
        IconComponent={SelectArrow}
        onChange={(event) => updateSelectedFilters("draft_resolution_type", event.target.value)}
      >
        <MenuItem className="italic" value={""}>Any</MenuItem>
        <MenuItem value={"committee report"}>Committee report</MenuItem>
        <MenuItem value={"stand-alone draft resolution"}>Stand-alone draft resolution</MenuItem>
        <MenuItem value={"committee draft resolution"}>Committee draft resolution</MenuItem>
        <MenuItem value={"meeting record"}>Meeting record</MenuItem>
      </Select>
    </FormControl>
  </div>

  const decisionModeFilter =
  <div className="form__element-container--left">
    <FormControl>
      <InputLabel>Decision mode</InputLabel>
      <Select
        style={{ width: 275 }}
        variant="filled"
        value={getFilterValue("decision_mode")}
        IconComponent={SelectArrow}
        onChange={(event) => updateSelectedFilters("decision_mode", event.target.value)}
      >
        <MenuItem className="italic" value={""}>Any</MenuItem>
        <MenuItem value={"recorded vote"}>Recorded vote</MenuItem>
        <MenuItem value={"non-recorded vote"}>Non-recorded vote</MenuItem>
        <MenuItem value={"adopted without vote"}>Adopted without vote</MenuItem>
        <MenuItem value={"silence procedure"}>Silence procedure</MenuItem>
      </Select>
    </FormControl>
  </div>

  const decisionTopicFilter =
  <div className="form__element-container--left">
    <FormControl>
      <InputLabel>Decision topic</InputLabel>
      <Select
        style={{ width: 250 }}
        variant="filled"
        value={getFilterValue("decision_topic")}
        IconComponent={SelectArrow}
        onChange={(event) => updateSelectedFilters("decision_topic", event.target.value)}
      >
        <MenuItem className="italic" value={""}>Any</MenuItem>
        <MenuItem value={"draft resolution"}>Draft resolution</MenuItem>
        <MenuItem value={"amendment"}>Amendment</MenuItem>
        <MenuItem value={"motion"}>Motion</MenuItem>
        <MenuItem value={"separate vote"}>Separate vote</MenuItem>
      </Select>
    </FormControl>
  </div>

  const decisionOutcomeFilter =
  <div className="form__element-container--left">
    <FormControl>
      <InputLabel>Decision outcome</InputLabel>
      <Select
        style={{ width: 225 }}
        variant="filled"
        value={getFilterValue("successful")}
        IconComponent={SelectArrow}
        onChange={(event) => updateSelectedFilters("successful", event.target.value)}
      >
        <MenuItem className="italic" value={""}>Either</MenuItem>
        <MenuItem value={"1"}>Successful</MenuItem>
        <MenuItem value={"0"}>Not successful</MenuItem>
      </Select>
    </FormControl>
  </div>

  const originalVoteFilter =
  <div className="form__element-container--left">
    <FormControl>
      <InputLabel>Original vote</InputLabel>
      <Select
        style={{ width: 225 }}
        variant="filled"
        value={getFilterValue("original_vote")}
        IconComponent={SelectArrow}
        onChange={(event) => updateSelectedFilters("original_vote", event.target.value)}
      >
        <MenuItem className="italic" value={""}>Any</MenuItem>
        <MenuItem value={"in favor"}>In favor</MenuItem>
        <MenuItem value={"against"}>Against</MenuItem>
        <MenuItem value={"abstaining"}>Abstaining</MenuItem>
        <MenuItem value={"not voting"}>Not voting</MenuItem>
      </Select>
    </FormControl>
  </div>

  const amendedVoteFilter =
  <div className="form__element-container--left">
    <FormControl>
      <InputLabel>Amended vote</InputLabel>
      <Select
        style={{ width: 225 }}
        variant="filled"
        value={getFilterValue("amended_vote")}
        IconComponent={SelectArrow}
        onChange={(event) => updateSelectedFilters("amended_vote", event.target.value)}
      >
        <MenuItem className="italic" value={""}>Any</MenuItem>
        <MenuItem value={"in favor"}>In favor</MenuItem>
        <MenuItem value={"against"}>Against</MenuItem>
        <MenuItem value={"abstaining"}>Abstaining</MenuItem>
        <MenuItem value={"not voting"}>Not voting</MenuItem>
      </Select>
    </FormControl>
  </div>

  const voteStatusFilter =
  <div className="form__element-container--left">
    <FormControl>
      <InputLabel>Vote status</InputLabel>
      <Select
        style={{ width: 275 }}
        variant="filled"
        value={getFilterValue("vote_was_amended")}
        IconComponent={SelectArrow}
        onChange={(event) => updateSelectedFilters("vote_was_amended", event.target.value)}
      >
        <MenuItem className="italic" value={""}>Either</MenuItem>
        <MenuItem value={"1"}>Vote was amended</MenuItem>
        <MenuItem value={"0"}>Vote was not amended</MenuItem>
      </Select>
    </FormControl>
  </div>

  var filtersForm = null;

  if (dataset == "member states") {

  }

  if (dataset == "sessions") {
    filtersForm =
    <div className="form__grid-fragment">
      <div className="form__row--left">
        {sessionTypeFilter}
      </div>
    </div>
  }

  if (dataset == "meeting_records") {
    filtersForm =
    <div className="form__grid-fragment">
      <div className="form__row--left">
        {sessionTypeFilter}
      </div>
      <div className="form__row--left">
        {minMeetingDateFilter}
        {maxMeetingDateFilter}
      </div>
    </div>
  }

  if (dataset == "draft_resolutions") {
    filtersForm =
    <div className="form__grid-fragment">
      <div className="form__row--left">
        {sessionTypeFilter}
      </div>
      <div className="form__row--left">
        {draftResolutionTypeFilter}
      </div>
    </div>
  }

  if (dataset == "resolutions") {
    filtersForm =
    <div className="form__grid-fragment">
      <div className="form__row--left">
        {sessionTypeFilter}
      </div>
      <div className="form__row--left">
        {minMeetingDateFilter}
        {maxMeetingDateFilter}
      </div>
      <div className="form__row--left">
        {draftResolutionTypeFilter}
      </div>
    </div>
  }

  if (dataset == "decisions") {
    filtersForm =
    <div className="form__grid-fragment">
      <div className="form__row--left">
        {sessionTypeFilter}
      </div>
      <div className="form__row--left">
        {minMeetingDateFilter}
        {maxMeetingDateFilter}
      </div>
      <div className="form__row--left">
        {draftResolutionTypeFilter}
      </div>
      <div className="form__row--left">
        {decisionModeFilter}
        {decisionTopicFilter}
      </div>
    </div>
  }

  if (dataset == "outcomes") {
    filtersForm =
    <div className="form__grid-fragment">
      <div className="form__row--left">
        {sessionTypeFilter}
      </div>
      <div className="form__row--left">
        {minMeetingDateFilter}
        {maxMeetingDateFilter}
      </div>
      {draftResolutionTypeFilter}
      <div className="form__row--left">
        {decisionModeFilter}
        {decisionTopicFilter}
      </div>
      <div className="form__row--left">
        {decisionOutcomeFilter}
      </div>
    </div>
  }

  if (dataset == "votes") {
    filtersForm =
    <div className="form__grid-fragment">
      <div className="form__row--left">
        {sessionTypeFilter}
      </div>
      <div className="form__row--left">
        {minMeetingDateFilter}
        {maxMeetingDateFilter}
      </div>
      {draftResolutionTypeFilter}
      <div className="form__row--left">
        {decisionModeFilter}
        {decisionTopicFilter}
      </div>
      <div className="form__row--left">
        {originalVoteFilter}
        {amendedVoteFilter}
      </div>
      <div className="form__row--left">
        {voteStatusFilter}
      </div>
    </div>
  }

  //--------------------------------------------------
  // select variables form
  //--------------------------------------------------

  var variablesForm =
  <div>
    <div className="download-tool__checkbox-grid">
      {getVariables().map((item, i) => (
        <div key={i} className="download-tool__checkbox-container">
          <FormControlLabel
            control={
              <Checkbox checked={selectedVariables.includes(item)} onChange={() => updateSelectedVariables(item)}/>
            }
            label={item}
          />
        </div>
      ))}
    </div>
  </div>

  //--------------------------------------------------
  // stepper
  //--------------------------------------------------

  // automatically scroll stepper to the top of the step
  // useEffect(() => {
  //   if(activeStep > -1) {
  //     scroll("stepper", (activeStep * 72) - 72);
  //   }
  // }, [activeStep])

  var downloadBar =
  <div className={isDownloading ? "download-tool__progress-bar-container" : "download-tool__progress-bar-container--hidden"}>
    <div className="download-tool__progress-bar">
      <LinearProgress />
    </div>
    <div className="download-tool__progress-bar-caption">
      Downloading. This may take a moment...
    </div>
  </div>

  const stepper =
  <div id="stepper" className="download-tool__container">
    <Stepper activeStep={activeStep} orientation="vertical">
      <Step id="step-1">
        <StepLabel onClick={() => {
          setActiveStep(0);
          resetFilters();
        }}>
          Select a version of the database
        </StepLabel>
        <StepContent>
          <div className="paragraph">
            Select the version of the UNGA-DM Database you'd like to use.
          </div>
          <div className='form__row--left'>
            <div className="form__element-container--left" style={{ width: 275 }}>
              <FormControl fullWidth>
                <InputLabel>Version</InputLabel>
                <Select
                  variant="filled"
                  value={version}
                  IconComponent={SelectArrow}
                  onChange={(event) => setVersion(event.target.value)}
                >
                  <MenuItem value={"v-2023-1"}>Version 2023.1</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="form__row--left">
            <div className="form__element-container--left">
              <Button variant="contained" startIcon={<NextIcon/>} onClick={() => {
                handleNextButton()
                resetFilters();
              }}>
                Next
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<BackIcon/>} onClick={handleBackButton}>
                Back
              </Button>
            </div>
          </div>
        </StepContent>
      </Step>
      <Step id="step-2">
        <StepLabel onClick={() => {
          setActiveStep(1);
          resetFilters();
        }}>
          Select a dataset
        </StepLabel>
        <StepContent>
          <div className="paragraph">
            You can download data from any of the following datasets in the UNGA-DM Database.
          </div>
          <div className="form__row--left">
            <div className="form__element-container--left">
              <FormControl>
                <InputLabel>Dataset</InputLabel>
                <Select
                  style={{ width: 275 }}
                  variant="filled"
                  value={dataset}
                  onChange={(event) => setDataset(event.target.value)}
                  IconComponent={SelectArrow}
                >
                  <MenuItem value="member_states">Member States</MenuItem>
                  <MenuItem value="sessions">Sessions</MenuItem>
                  <MenuItem value="meeting_records">Meeting Records</MenuItem>
                  <MenuItem value="draft_resolutions">Draft Resolutions</MenuItem>
                  <MenuItem value="resolutions">Resolutions</MenuItem>
                  <MenuItem value="decisions">Decisions</MenuItem>
                  <MenuItem value="outcomes">Outcomes</MenuItem>
                  <MenuItem value="votes">Votes</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="form__row--left">
            <div className="form__element-container--left">
              <Button variant="contained" startIcon={<NextIcon/>} onClick={() => {
                handleNextButton();
                resetFilters();
              }}>
                Next
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<BackIcon/>} onClick={handleBackButton}>
                Back
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<CodebookIcon/>} onClick={() => setCodebookDialogOpen(true)}>
                Codebook
              </Button>
            </div>
          </div>
        </StepContent>
      </Step>
      <Step id="step-3">
        <StepLabel onClick={() => setActiveStep(2)}>
          Select observations
        </StepLabel>
        <StepContent>
          <div className="paragraph">
            Use the filters below to select the observations you'd like to include in your dataset. By default, all observations are included. Use the button below to check the codebook if you have a question about one of the possible filter variables.
          </div>
          <div className="form__grid--left">
            {filtersForm}
            <div className="form__row--left">
              <div className="form__element-container--left">
                <Button
                  variant="contained"
                  startIcon={<NextIcon/>}
                  onClick={() => {
                    handleNextButton();
                  }}
                >
                  Next
                </Button>
              </div>
              <div className="form__element-container--left">
                <Button variant="outlined" startIcon={<BackIcon/>} onClick={handleBackButton}>
                  Back
                </Button>
              </div>
              <div className="form__element-container--left">
                <Button variant="outlined" startIcon={<ResetIcon/>} onClick={resetFilters}>
                  Reset filters
                </Button>
              </div>
              <div className="form__element-container--left">
                <Button variant="outlined" startIcon={<CodebookIcon/>} onClick={() => setCodebookDialogOpen(true)} >
                  Codebook
                </Button>
              </div>
            </div>
          </div>
        </StepContent>
      </Step>
      <Step id="step-4">
        <StepLabel onClick={() => setActiveStep(3)}>
          Select variables
        </StepLabel>
        <StepContent>
          <div className="paragraph">
            Select all of the variables you'd like to include in your dataset. Some variables are required because they identify the observation. By default, all variables are included. Use the button below to check the codebook if you're not sure which variables you want.
          </div>
          {variablesForm}
          <div className="form__row--left">
            <div className="form__element-container--left">
              <Button
                variant="contained"
                startIcon={<NextIcon/>}
                onClick={() => {
                  handleNextButton();
                  getData();
                  getObservations();
                }}
              >
                Next
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<BackIcon/>} onClick={handleBackButton}>
                Back
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<SelectIcon/>} onClick={() => selectAllVariables()}>
                Select all
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<DeselectIcon/>} onClick={() => deselectAllVariables()}>
                Deselect all
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<CodebookIcon/>} onClick={() => setCodebookDialogOpen(true)}>
                Codebook
              </Button>
            </div>
          </div>
        </StepContent>
      </Step>
      <Step id="step-5">
        <StepLabel onClick={() => setActiveStep(4)}>
          Preview selected data
        </StepLabel>
        <StepContent>
          <div className="paragraph">
            You can preview the data before you download it to make sure it's what you want. If it's not, you can go back and modify your selection.
          </div>
          <div className="form__row--left">
            <div className="form__element-container--left">
              <Button variant="contained" startIcon={<PreviewIcon/>} onClick={() => {
                setPreviewDialogOpen(true);
                getObservations();
                getData();
              }}>
                Preview
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<NextIcon/>} onClick={handleNextButton}>
                Next
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<BackIcon/>} onClick={handleBackButton}>
                Back
              </Button>
            </div>
          </div>
        </StepContent>
      </Step>
      <Step id="step-6">
        <StepLabel onClick={() => setActiveStep(5)}>
          Agree to cite the database
        </StepLabel>
        <StepContent>
          <CitationComponent/>
          <div className="form__row--left">
            <div className="form__element-container--left">
              <Button variant="contained" startIcon={<AgreeIcon/>} onClick={handleNextButton} >
                Agree
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<BackIcon/>} onClick={handleBackButton}>
                Back
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<CancelIcon/>} onClick={handleRestartButton}>
                Cancel
              </Button>
            </div>
          </div>
        </StepContent>
      </Step>
      <Step id="step-7">
        <StepLabel onClick={() => setActiveStep(5)}>
          Download codebook
        </StepLabel>
        <StepContent>
          <div className="paragraph">
            You can download the codebook for this dataset as a PDF file or a CSV file. The codebook contains a description of the dataset and a description of each variable in the dataset.
          </div>
          <div className="form__row--left">
            <div className="form__element-container--left">
              <Button
                variant="contained"
                startIcon={<DownloadIcon/>}
                href={"/files/UNGA-DM-Database-" + version + "-codebook.pdf"}
                download={"UNGA-DM-Database-" + version + "-codebook.pdf"}
                onClick={() => {
                  trackAction(user, "download_tool_codebook", "pdf");
                }}
              >
                Codebook (PDF)
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button
                variant="contained"
                startIcon={<DownloadIcon/>}
                href={"/files/UNGA-DM-Database-" + version + "-codebook.csv"}
                download={"UNGA-DM-Database-" + version + "-codebook.csv"}
                onClick={() => {
                  trackAction(user, "download_tool_codebook", "csv");
                }}
              >
                Codebook (CSV)
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<NextIcon/>} onClick={handleNextButton}>
                Next
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<BackIcon/>} onClick={handleBackButton}>
                Back
              </Button>
            </div>
          </div>
        </StepContent>
      </Step>
      <Step id="step-8">
        <StepLabel onClick={() => setActiveStep(5)}>
          Download data
        </StepLabel>
        <StepContent>
          <div className="paragraph">
            Your data will download as a <span className='code'>.zip</span> file that includes a <span className='code'>.csv</span> file with the data and a <span className='code'>.txt</span> file that documents your search criteria, for replication purposes. This may take a couple of minutes if you've selected a large number of observations. Please <a className="link" href="mailto: joshfjelstul@gmail.com">let us know</a> if you have any questions about the data.
          </div>
          <div className="form__row--left">
            <div className="form__element-container--left">
              <Button variant="contained" startIcon={<DownloadIcon/>} onClick={handleDownloadButton}>
                Data (ZIP)
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<BackIcon/>} onClick={handleBackButton}>
                Back
              </Button>
            </div>
            <div className="form__element-container--left">
              <Button variant="outlined" startIcon={<RestartIcon/>} onClick={handleRestartButton}>
                Restart
              </Button>
            </div>
          </div>
          {downloadBar}
        </StepContent>
      </Step>
    </Stepper>
  </div>

  //--------------------------------------------------
  // parallax
  //--------------------------------------------------

  const parallaxContent =
  <div>
    <div className="page-title-container">
      <div className="page-title">
        Download Tool
      </div>
    </div>
  </div>

  //--------------------------------------------------
  // return page
  //--------------------------------------------------

  return (
    <div>
      <div className="block">
        <div className="title">
          Custom table (advanced users)
        </div>
        <div className="paragraph">
          The UNGA-DM Download Tool makes it easy to download exactly the data you're interested in from the UNGA-DM Database. This tool walks you through the process of choosing a version of the database, choosing a dataset, choosing observations, and choosing variables. You can preview the data, download citations for the working paper and the database, and download the codebook.
        </div>
        <div className="form__button-container--center">
          <Button variant="contained" onClick={() => {
            handleRestartButton();
            scroll("step-1", -72);
          }}>
            Get started
          </Button>
        </div>
        {stepper}
        {codebookDialog}
        {previewDialog}
      </div>
    </div>
  );
};
