// React components
import React, { useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";

// API components
import Axios from "axios";

// material-ui components
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// components
import ParallaxComponent from "./ParallaxComponent"

async function loginUser(credentials) {
  var url = "https://api.unvotes.org/authenticate";
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials)
  })
  .then(data => data.json())
}

export default function Login(props) {

  const { onSubmit } = props;

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [failure, setFailure] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const history = useHistory();

  const location = useLocation();

  const handleSubmit = async (value) => {
    const credentials = {
      "username": username,
      "password": password
    };
    try {
      const token = await loginUser(credentials);
      setFailure(false);
      onSubmit(token);
      if (location.pathname.includes("authentication")) {
        history.goBack();
      }
    } catch(error) {
      console.log(error);
      setFailure(true);
    }
  };

  var loginMessage =
  <div className={failure ? "login-form__message" : "login-form__message--hidden"} >
    Incorrect username or password.
  </div>

  const loginForm =
  <div className="login-form__container">
    <div className="form__row--left">
      <div className="form__element-container--left">
        <TextField
          style={{ width: 275 }}
          variant="filled"
          label="Username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
        />
      </div>
    </div>
    <div className="form__row--left">
      <div className="form__element-container--left">
        <TextField
          style={{ width: 275 }}
          variant="filled"
          label="Password"
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          InputProps={{
            endAdornment:
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }}
        />
      </div>
    </div>
    <div className="form__row--left">
      <div className="form__element-container--left">
        <Button variant="contained" onClick={handleSubmit}>Log in</Button>
      </div>
    </div>
    {loginMessage}
  </div>

  return(
    <div>
      <div className='login-form__left-panel'>
        <img className='login-form__image' src='media/cover-image.jpg'/>
      </div>
      <div className='login-form__right-panel'>
        {loginForm}
      </div>
    </div>
  )
}
