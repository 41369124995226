// react components
import React, { Componen, useStatet } from "react";

// mui components
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// mui icons
import SelectArrow from "@mui/icons-material/KeyboardArrowDownRounded";

import { trackAction } from "./Utilities";

export default function FAQComponent (props) {

  var { question, answer } = props;

  const [open, setOpen] = React.useState(false);

  return (
    <div className="faqs__container">
      <Accordion onChange={() => {
        setOpen(!open);
      }}>
        <AccordionSummary>
          <div className="faqs__question-container">
            {question}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="faqs__answer-container">
            {answer}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
