// React components
import React, { useEffect, useState } from 'react';
import { Route, Redirect, Link, Switch, useLocation, useHistory } from 'react-router-dom';

// API components
import Axios from 'axios';

// MUI components
import { styled, useTheme, Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';

// MUI icons
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle'

// pages
import LogInPage from './LogInPage';

// utilities
import { trackAction } from "./Utilities";

const Authenticate = (token) => {
  const [user, setUser] = React.useState(null);
  const url = 'https://www.api.unvotes.org/check-authentication';
  const headers = {
    'Content-Type': 'application/json',
    'authorization': token,
  }
  useEffect(() => {
    Axios.get(url, { headers : headers })
    .then(response => {
      setUser(response.data );
    })
    .catch(error => {
      console.log(error);
    });
  }, [token])
  return user;
};

interface Props {
  window?: () => Window;
}

export default function App (props) {

  const { APIURL, data, logo, drawerWidth, appBarHeight, sidebarOnTop } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [token, setToken] = useState(JSON.parse(sessionStorage.getItem('token')));

  const theme = useTheme();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    trackAction(username, "page_view", location.pathname);
  }, [location]);

  useEffect(() => {
    var element = document.querySelector("#panel");
    if (element !== null) {
      element.scroll(0, 0);
    }
  }, [location])

  const storeToken = (value) => {
    sessionStorage.setItem('token', JSON.stringify(value.token));
    setToken(value.token);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    setToken(null);
    history.go(0);
  }

  const username = Authenticate(token);

  var authenticationPanel = null;
  if (username !== null) {
    authenticationPanel =
    <div className='auth-panel' style={{ height: appBarHeight }}>
      <div className='auth-panel__username-container'>
        <AccountCircle/>
        <div className='auth-panel__username'>{username}</div>
      </div>
      <div className='auth-panel__button' onClick={handleLogout}>Log out</div>
    </div>
  } else {
    authenticationPanel =
    <div className='auth-panel' style={{ height: appBarHeight }}>
      <div className='auth-panel__username-container'>
        <AccountCircle/>
        <Link className='auth-panel__button' to='/login'>Log in</Link>
      </div>
    </div>
  }

  const menu =
  <div className='menu' style={{ height: appBarHeight, zIndex: 1000 }}>\
    {/* <div className='menu__search-container'>
      <Button
        variant="outlined"
        startIcon={<SearchIcon/>}
        component={Link}
        to="/search"
      >
        Votes
      </Button>
    </div> */}
    <Link className='menu__logo-container' to={data[0].url}>
      {logo}
    </Link>
    {authenticationPanel}
    <Box
      sx={{
        display: { mobile: 'block', desktop: 'none' },
        paddingLeft: '16px',
      }}
    >
      <IconButton onClick={handleDrawerToggle}>
        <MenuIcon sx={{color: theme.palette.primary}} />
      </IconButton>
    </Box>
  </div>

  const sidebarContent =
  <div className='sidebar' style={{ top: appBarHeight }}>
    <div className='sidebar__content'>
      {data.map((item, i) => (
        <Link key={i} className={location.pathname.includes(item.url) ? 'sidebar__item sidebar__item--selected' : 'sidebar__item'} to={item.url}>
          <item.icon className='sidebar__icon'/><span>{item.label}</span>
        </Link>
      ))}
    </div>
  </div>

  const panel =
  <Box id="panel" className='panel' sx={{ top: appBarHeight, left: { mobile: '0px', desktop: drawerWidth + 'px' } }}>
    <Switch>
      <Redirect exact from='/' to='/database'/>
      {data.map((item, i) => (
        <Route path={item.url} exact render={() => (item.protected && !username) ? <LogInPage onSubmit={storeToken}/> : <item.panel token={token} APIURL={APIURL} user={username} />} />
      ))}
      <Route path='/login' exact render={() => <LogInPage onSubmit={storeToken}/>} />
    </Switch>
  </Box>

  const sidebar =
  <div>
    <Drawer
      variant='temporary'
      open={drawerOpen}
      onClose={handleDrawerToggle}
      sx={{
        display: { mobile: 'block', desktop: 'none' },
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          marginTop: '0px',
          zIndex: 2000,
        },
      }}
    >
      {sidebarContent}
    </Drawer>
    <Drawer
      variant='permanent'
      sx={{
        display: { mobile: 'none', desktop: 'block' },
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          paddingTop: appBarHeight + 'px',
          zIndex: sidebarOnTop ? 1100 : 900,
        },
        '& .MuiPaper-root.MuiDrawer-paper': {
          boxShadow: 'none'
        }
      }}
    >
      {sidebarContent}
    </Drawer>
  </div>

  var app = <LogInPage onSubmit={storeToken} />
  if (username) {
    app =
    <div className='dashboard'>
      {menu}
      {sidebar}
      {panel}
    </div>
  }

  return (
    <div>
      {app}
    </div>
  );
};
