import DownloadToolComponent from "./DownloadToolComponent";
import BioComponent from "./BioComponent";
import DatabaseComponent from "./DatabaseComponent";
import SearchToolComponent from "./SearchToolComponent";
// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import FAQComponent from "./FAQComponent";
import WorldMap from "./WorldMap";
import { bios } from "./BioData";

import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import GitHubIcon from '@mui/icons-material/GitHub';
import ViewIcon from '@mui/icons-material/Visibility';

import { trackAction } from "./Utilities";

const panelFooter =
  <div className="panel__footer">
    Designed and programmed by Joshua C. Fjelstul, Ph.D.
  </div>

const IntroductionPanel = (props) => {
  return(
    <div className='panel__content'>
      <div className="block">
        <div className="title">
          The UNGA-DM Database
        </div>
        <div className="paragraph">
          The United Nations General Assembly Decision-Making (UNGA-DM) Database is a comprehensive database of resolutions, decisions, and votes in the United Nations General Assembly (UNGA) from 1946 through 2021. It was created by Josh Fjelstul, Simon Hug, and Christopher Kilby.
        </div>
        <div className="paragraph">
          The UNGA-DM Database covers all resolution-related decisions in regular sessions, special sessions, and emergency special sessions from the 1st Regular Session, starting in 1946, through the 75th Regular Session, ending in 2021. This addresses the limited coverage in other databases.
        </div>
        <div className="plot__container">
          <img className="plot__image" src="circular-dendrogram.png"/>
          <div className="plot__caption">
            This visualization, called a circular dendrogram, shows the structure of the UNGA-DM Database. The inner circle (green) shows the four different types of decisions. The middle circle (blue) shows the mode of the decision (how the decision was taken). The outer circle (red) shows the outcome of the vote. The size of the node indicates how many observations there are at each node.
          </div>
        </div>
        <div className="paragraph">
          The UNGA-DM Database covers all draft resolution-related decisions, including decisions on the final passage of draft resolutions, amendments to draft resolutions, motions on draft resolutions, and separate votes on parts of draft resolutions. It includes decisions taken by recorded vote, decisions taken by non-recorded vote (no longer used), and decisions taken without a vote. It also covers all successful and failed votes.
        </div>
        <div className="paragraph">
          In addition to data on resolutions, decisions, and votes, the database also includes supporting information on UN member states, UNGA sessions, UNGA meeting records, and UNGA draft resolutions.
        </div>
      </div>
      {panelFooter}
    </div>
  )
}

const DownloadPanel = (props) => {
  const { token, user, APIURL } = props;
  return(
    <div className='panel__content'>
      <DatabaseComponent token={token} user={user}/>
      <div className='divider'/>
      <DownloadToolComponent token={token} APIURL={APIURL} user={user} />
      {panelFooter}
    </div>
  )
}

const SearchPanel = (props) => {
  const { token, user, APIURL } = props;
  return(
    <div className='panel__content'>
      <SearchToolComponent token={token} APIURL={APIURL} user={user} />
      {panelFooter}
    </div>
  )
}

const TutorialPanel = (props) => {
  return(
    <div className='panel__content'>
      <div className="block">
        <div className="title">
          Tutorial
        </div>
        <div className="paragraph">
          This tutorial walks you through using the database and covers best practices for merging the data. We'll use <span className='code'>dplyr</span> in <span className='code'>R</span> in this tutorial, but the best practices we'll cover are the same regardless of what software you use.
        </div>
        <div className="sub-title">
          Structure of the database
        </div>
        <div className="paragraph">
          We'll start off by taking a look at the structure of the UNGA-DM Database. The UNGA-DM Database is a relational database that consists of rectangular tables. There's one dataset per unit of observation. Each dataset includes a primary key and one or more variables that are unique to the table. The primary key unqiuely identifies each observation. The primary key in the <span className="code">votes</span> table is actually a compound primary key consisting of two variables, <span className="code">decision_id</span> and <span className="code">member_state_id</span>. Some datasets also include foreign keys, which are variables that are primary keys in one of the other datasets.
        </div>
        <div className="paragraph">
          You can use foreign keys to merge in data from other tables in the UNGA-DM Database. For example, the <span className="code">decisions</span> dataset includes a foreign key called <span className="code">session_id</span>, which is the primary key in the <span className="code">sessions</span> table. This variable indicates which session each decision in the <span className="code">decisions</span> tables comes from. You can use the <span className="code">session_id</span> variable in the <span className="code">decisions</span> dataset to merge in other variables from the <span className="code">sessions</span> table.
        </div>
        <div className="paragraph">
          In general, every value of a variable that is a foreign key must have a match in the corresponding primary key. There are two exceptions. First, the <span className="code">resolutions</span> dataset includes a foreign key called <span className="code">meeting_record_id</span>, which indicates the meeting at which the resolution was adopted. However, there are a set of resolutions that were adopted by the silence procedure, and these resolutions were not adopted at a meeting, so there is no corresponding value <span className="code">meeting_record_id</span> in the <span className="code">meeting_record</span> dataset. Second, the <span className="code">decisions</span> dataset includes a foreign key called <span className="code">resolution_id</span>, which indicates the resolution that the decision pertains to. However, some decisions are on draft resolutions that are not ultimately adopted, so there is no corresponding value of <span className="code">resolution_id</span> in the <span className="code">resolutions</span> dataset.
        </div>
        <div className="sub-title">
          Merging tables
        </div>
        <div className="paragraph">
          Next, we'll look at how to merge tables from the UNGA-DM Database together. As a relational database, each table in the UNGA-DM Database includes a primary key, foreign keys that correspond to primary keys in other tables, and variables that are relevant to that unit of observation. To include variables that vary at another unit of obseravtion in a table, we'll need to use primary and foreign keys to merge the data. We'll walk through merging variables from other tables into the <span className="code">decisions</span> table and the <span className="code">votes</span> table. This covers the most common use cases.
        </div>
        <div className="paragraph">
          We'll start with the <span className="code">decisions</span> table. Let's say we want to merge in data about the type of the session (Regular Session, Special Session, or Emergency Special Session) and the date of the meeting record associated with each decision. Merging in these variables will let us filter the <span className="code">decisions</span> table by session type and date. The first thing we need to do is check the SQL schema to see what the primary and foreign keys are in the <span className="code">decisions</span> table. Here's what the schema looks like for this table:
        </div>
        <pre className="code-block">
{`DROP TABLE IF EXISTS decisions;
CREATE TABLE decisions(
  decision_id VARCHAR(200) NOT NULL,
  session_id VARCHAR(200) NOT NULL,
  meeting_record_id VARCHAR(200) NOT NULL,
  draft_resolution_id VARCHAR(200) NOT NULL,
  resolution_id VARCHAR(200),
  decision_mode ENUM(
    'adopted without vote',
    'non-recorded vote',
    'recorded vote'
  ) NOT NULL,
  decision_topic ENUM(
    'draft resolution',
    'separate vote',
    'amendment',
    'motion'
  ) NOT NULL,
  important BOOLEAN NOT NULL,
  PRIMARY KEY (decision_id),
  FOREIGN KEY (session_id) REFERENCES sessions (session_id),
  FOREIGN KEY (meeting_record_id) REFERENCES meeting_records (meeting_record_id),
  FOREIGN KEY (draft_resolution_id) REFERENCES draft_resolutions (draft_resolution_id),
  FOREIGN KEY (resolution_id) REFERENCES resolutions (resolution_id)
);`}
        </pre>
        <div className='paragraph'>
          This SQL command creates the <span className="code">decisions</span> table. We can see that the table has a single primary key: <span className="code">decision_id</span>. It also has four foreign keys: <span className="code">session_id</span>, <span className="code">meeting_record_id</span>, <span className="code">draft_resolution_id</span>, and <span className="code">resolution_id</span>. These foreign keys are the variables that we can use to merge in data from other tables. Each of these foreign keys corresponds to a primary key in another table. In this example, we'll use <span className="code">session_id</span> to merge in variables from the <span className="code">sessions</span> table and <span className="code">meeting_record_id</span> to merge in data from the <span className="code">meeting_records</span> table.
        </div>
        <div className='paragraph'>
          We'll use <span className="code">dplyr</span> in <span className="code">R</span> to merge the <span className="code">sessions</span> and <span className="code">meeting_records</span> tables into the <span className="code">decisions</span> table. Let's assume we already have these tables loaded in <span className="code">R</span> as <span className="code">tibbles</span>. Let's take a look at the code for merging in the data, and then we'll break it down.
        </div>
        <pre className="code-block">
{`decisions <- decisions |>
  left_join(
    sessions |>
      select(
        session_id, session_type
      ),
    by = "session_id"
  ) |>
  left_join(
    meeting_records |>
      select(
        meeting_record_id, meeting_date
      ),
    by = "meeting_record_id"
  )`}
        </pre>
        <div className='paragraph'>
          If you're not already familiar with it, <span className="code">|></span> is the <span className="code">base R</span> pipe operator. It lets us chain together functions such that the output of one function is used as the input for the next. It's a really convenient way to apply multiple operations to the same <span className="code">tibble</span> in sequence. It's also more readable than other ways of writing <span className="code">R</span> code.
        </div>
        <div className='paragraph'>
          Our pipe has two steps, one for merging in variables from the <span className="code">sessions</span> table and one for merging in data from the <span className="code">meeting_records</span> table. Each step in our pipe also includes a nested pipe that we use to select the variables from each table that we want to merge in. From the <span className="code">sessions</span> table, we're selecting two variables: <span className="code">session_id</span> and <span className="code">session_type</span>. From the <span className="code">meeting_records</span> table, we're also selecting two variables: <span className="code">meeting_record_id</span> and <span className="code">session_id</span>.
        </div>
        <div className='paragraph'>
          It's important that we include <span className="code">session_id</span> and <span className="code">meeting_record_id</span> because these are the variables that we're using to merge the tables. <span className="code">session_id</span> is a foreign key in the <span className="code">decisions</span> table and the primary key in the <span className="code">sessions</span> table. Similarly, <span className="code">meeting_record_id</span> is a foreign key in the <span className="code">decisions</span> table and the primary key in the <span className="code">meeting_records</span> table.
        </div>
        {/* <div className='paragraph'>
          If you know <span className="code">SQL</span>, we could also do the same thing with an <span className="code">SQL</span> query:
        </div>
        <pre className="code-block">
{`SELECT * FROM resolutions
  LEFT JOIN (SELECT session_id, session_type FROM sessions) AS a USING (session_id)
  LEFT JOIN (SELECT meeting_record_id, meeting_date FROM meeting_records) AS b USING (meeting_record_id)`}
        </pre>
        <div className='paragraph'>
          Note that in <span className="code">SQL</span>, we have to create temporary tables called <span className="code">a</span> and <span className="code">b</span>, or whatever you want to call them. In <span className="code">R</span>, we can use pipes to skip this step.
        </div> */}
        <div className='paragraph'>
          Now that we have these variables merged in, we can filter the <span className="code">decisions</span> table. Let's say we want just resolutions adopted during Regular Sessions since January 1, 2000. We can use pipes to apply these filters.
        </div>
        <pre className="code-block">
{`decisions <- decisions |>
  filter(
    session_type == "regular session"
  ) |>
  filter(
    meeting_date >= "2000-01-01"
  )`}
        </pre>
        <div className='paragraph'>
          Next, we'll look at the <span className="code">votes</span> table. Let's say we want to filter this table by session type and by meeting date, just like in the last example. Looking at the SQL schema, we can see that the table has a compound primary key: the combination of <span className="code">decision_id</span> and <span className="code">member_state_id</span> uniquely identify observations. There are two foreign keys: <span className="code">decision_id</span> and <span className="code">member_state_id</span>.
        </div>
        <div className='paragraph'>
          In this case, we'll need to use <span className="code">decision_id</span> to merge in <span className="code">session_id</span> and <span className="code">meeting_record_id</span>, which are both decision-level variables, from the <span className="code">decisions</span> table. Then, we'll use those two variables to merge in <span className="code">session_type</span> from the <span className="code">sessions</span> table and <span className="code">meeting_date</span> from the <span className="code">meeting_records</span> table.
        </div>
        <pre className="code-block">
{`votes <- votes |>
  left_join(
    decisions |>
      select(
        decision_id, session_id, meeting_record_id,
      ),
    by = "session_id"
  ) |>
  left_join(
    sessions |>
      select(
        session_id, session_type
      ),
    by = "session_id"
  ) |>
  left_join(
    meeting_records |>
      select(
        meeting_record_id, meeting_date
      ),
    by = "meeting_record_id"
  )`}
        </pre>
        <div className='paragraph'>
          This pipe is similar to the previous one, but it includes one extra step, which is to merge in data from the <span className="code">decisions</span> table. After we do that, we can merge in variables from the <span className="code">sessions</span> and <span className="code">meeting_records</span> tables just as before. With those variables merged in, we can then filter the <span className="code">votes</span> table.
        </div>
        <pre className="code-block">
{`votes <- votes |>
  filter(
    session_type == "regular session"
  ) |>
  filter(
    meeting_date >= "2000-01-01"
  )`}
        </pre>
        <div className="sub-title">
          Merging in external country-level data
        </div>
        <div className="paragraph">
          The <span className="code">votes</span> table includes data on how every UN member state voted with respect to each recorded vote. To merge country-level data into this table, we can use the <span className="code">member_state_id</span> variable. This variable is the Correlates of War (COW) country code for each member state. This is the most commonly used country code by international relations scholars. In general, every UN member state has one unqiue <span className="code">meeting_record_id</span> value. However, there are three important exceptions: Germany, China, and Yemen. Here's what you need to know about these member state before merging in country-level data.
        </div>
        <div className="paragraph">
          COW has three code related to Germany. <span className="code">260</span> is the Federal Republic of Germany, or West Germany. <span className="code">245</span> is the German Democratic Republic, or East Germany. And <span className="code">255</span> is modern Germany, after reunification in 1990. Following COW, we use <span className="code">260</span> for West Germany, <span className="code">255</span> for East Germany, and <span className="code">255</span> for modern Germany. We stop using <span className="code">245</span> and switch from <span className="code">260</span> to <span className="code">255</span> on October 3, 1990, which is the date of unification.
        </div>
        <div className="paragraph">
          COW uses <span className="code">710</span> for China and <span className="code">713</span> for Taiwan. The UN seat called <span className="code">China</span> was originally held by the Republic of China, which was exiled to Taiwan during the Chinese Civil War. On November 15, 1971, the UN recognized the People's Republic of China, which had controlled mainland China since the war, as the holder of the <span className="code">China</span> seat. We switch from <span className="code">713</span> to <span className="code">710</span> on November 15, 1971.
        </div>
        <div className="paragraph">
          COW also has three codes related to Yemen. <span className="code">678</span> is what COW calls the Yemen Arab Republic, what the UN informally calls Yemen and formally calls Yemen Arab Republic, and what is often called North Yemen. <span className="code">680</span> is what COW called the Yemen People's Republic, what the UN informally calls Democratic Yemen and formally calls the People's Democratic Republic of Yemen, and what is often called South Yemen. And <span className="code">679</span> is modern-day Yemen, which the UN informally calls Yemen and formally calls the Republic of Yemen. COW considers modern-day Yemen, <span className="code">679</span>, to be the successor state of the Yemen Arab Republic, <span className="code">678</span>. Following COW, we stop using <span className="code">680</span> and switch from <span className="code">678</span> to <span className="code">679</span> on May 22, 1990, which is the date of unification.
        </div>
        <div className="paragraph">
          When merging country-level data with the <span className='code'>votes</span> table, you should always use the <span className='code'>member_state_id</span> variable. Using the name of the country isn't reliable and may result in merging errors. The <span className='code'>votes</span> table includes three country name variables: the common name of the country (one per unique member state ID), which is the name used in the <span className="code">member_states</span> table, the official name of the seat at the time the vote was taken, and the current official name of the seat.
        </div>
        <div className="paragraph">
          We include these country names in the <span className='code'>votes</span> table votes table for convenience, so you don't have to look up country codes when looking through the data, but you shouldn't use them for merging unless you really know what you're doing. If you do use the country names for merging, always check that a merge hasn't resulted in duplicate observations. In particular, make sure you check that you're correctly handling Germany, China, and Yemen.
        </div>
        <div className="paragraph">
          When merging in country-level data, always check to make sure that the number of observations in the <span className='code'>votes</span> table hasn't changed after the merge.
        </div>
        <div className="paragraph">
          Suppose we have some country-level data in a table called <span className='code'>country_data</span> that we want to merge into the <span className='code'>votes</span> table. Suppose also that <span className='code'>country_data</span> has a variable called <span className='code'>ccode</span> that's the COW code for each country. We can do this using a left join:
        </div>
        <pre className="code-block">
{`votes <- votes |>
  left_join(
    country_data,
    by = c("member_state_id" = "ccode")
  )`}
        </pre>
      </div>
      {panelFooter}
    </div>
  )
}

const DocumentationPanel = (props) => {
  const { user } = props;
  return(
    <div className='panel__content'>
      <div id='codebook' className="block">
        <div className="title">
          Codebook
        </div>
        <div className="paragraph">
          The codebook for the UNGA-DM Database includes a description of each dataset and descriptions of each variable. You can download the codebook as a <span className="code">.pdf</span> file or as a <span className="code">.csv</span> file.
        </div>
        <div className="form__row--center">
          <div className="form__element-container--center">
            <Button
              variant="contained"
              startIcon={<DownloadIcon/>}
              href="files/UNGA-DM-Database-v-2023-1-codebook.pdf"
              download="UNGA-DM-Database-v-2023-1-codebook.pdf"
              onClick={() => {
                trackAction(user, "download_codebook", "pdf");
              }}
            >
              Codebook (PDF)
            </Button>
          </div>
          <div className="form__element-container--center">
            <Button
              variant="contained"
              startIcon={<DownloadIcon/>}
              href="files/UNGA-DM-Database-v-2023-1-codebook.csv"
              download="UNGA-DM-Database-v-2023-1-codebook.csv"
              onClick={() => {
                trackAction(user, "download_codebook", "csv");
              }}
            >
              Codebook (CSV)
            </Button>
          </div>
        </div>
      </div>
      <div className='divider'/>
      <div id='working-paper' className="block">
        <div className="title">
          Working paper
        </div>
        <div className="paragraph">
          Our working paper, "Decision-Making in the United Nations General Assembly: A Comprehensive Database of Resolutions, Decisions, and Votes", introduces the UNGA-DM Database and includes replication studies that highlight how using our data makes a difference.
        </div>
        <div className="quote">
          Existing databases for United Nations General Assembly (UNGA) voting (e.g., Bailey et al. 2017) provide excellent coverage of voting on resolutions that were adopted by recorded vote. These databases, however, have some known limitations. They were built on historical dataset (e.g., the ICPSR dataset) that have varying coverage and definitions. They do not cover resolutions adopted by consensus. They do not consistently cover votes on draft resolutions that failed (i.e., votes on resolutions that were not adopted), or votes leading up to consideration by the UNGA. In this paper, we present an updated dataset that attempts to expand and improve the consistency of the UNGA decisions covered. We provide some comparisons of our database with other UNGA datasets, as well as explore the implications of more complete data for existing and future research.
        </div>
        <div className="form__button-container--center">
          <Button
            variant="contained"
            startIcon={<DownloadIcon/>}
            href="files/UNGA-DM-Database-working-paper.pdf"
            download="UNGA-DM-Database-working-paper.pdf"
            onClick={() => {
              trackAction(user, "download_working_paper", "pdf");
            }}
          >
            Working paper (PDF)
          </Button>
        </div>
      </div>
      <div className='divider'/>
      <div id='sql-schema' className="block">
        <div className="title">
          SQL schema
        </div>
        <div className="paragraph">
          The UNGA-DM database is a relational database. We use MariaDB to power the database. You can download the SQL schema for the database to learn more about how the tables are structured and which variables are primary and foreign keys.
        </div>
        <div className="form__button-container--center">
          <Button
            variant="contained"
            startIcon={<DownloadIcon/>}
            href="files/UNGA-DM-Database-v-2023-1-SQL-schema.txt"
            download="UNGA-DM-Database-v-2023-1-SQL-schema.txt"
            onClick={() => {
              trackAction(user, "download_sql_schema", "txt");
            }}
          >
            SQL schema (TXT)
          </Button>
        </div>
      </div>
      {panelFooter}
    </div>
  )
}

const FAQsPanel = (props) => {
  return(
    <div className='panel__content'>
      <div className='block'>
        <div className="title">
          FAQs
        </div>
        <div className="paragraph">
          We've compiled a list of frequently asked questions (FAQs) about the UNGA-DM Database. If you're looking for a practical guide to using the database, check out our <Link className='link' to='tutorial'>online tutorial</Link>. If you have a question about the advantages of our database compared to other sources, please read our <Link className='link' to='documentation#working-paper'>working paper</Link>. If you have a question about the contents of a table or about the definition of a variable, please consult the <Link className='link' to='documentation#codebook'>UNGA-DM Codebook</Link>. If you have a question about the design of our SQL database, please download the <Link className='link' to='documentation#sql-schema'>SQL schema</Link>. If you have a question that we don't cover in these resources, please <a className='link' href="mailto: joshfjelstul@gmail.com">contact us</a>.
        </div>
        <div className='faqs__list-container'>
          <FAQComponent
            question="What are the options for downloading the data?"
            answer={
              <div>
                <div className="paragraph">
                  There are two ways to download data from the UNGA-DM Database. First, you can download the entire database. Second, you can use the UNGA-DM Download Tool to download custom tables.
                </div>
              </div>
            }
          />
          <FAQComponent
            question="What file formats is the database available in?"
            answer={
              <div>
                <div className="paragraph">
                  You can download the full database as CSV files, as RData files, or as an SQLite file. You can use the UNGA-DM Download Tool to download custom tables as CSV files.
                </div>
              </div>
            }
          />
          <FAQComponent
            question="What kind of decisions does the database cover?"
            answer={
              <div>
                <div className="paragraph">
                  The UNGA-DM Database currently includes all resolution-related decisions from Regular Session 1-75, Special Sessions 1-22, and Emergency Special Sessions 1-10. There are four types of decisions: final passage, motions, amendments, and separate votes (e.g., votes on individual paragraphs). Decisions can be taken without a vote, by a non-recorded vote (no longer used), or by a recorded vote (e.g., a roll-call vote).
                </div>
              </div>
            }
          />
          <FAQComponent
            question="What are resolution-related decisions?"
            answer={
              <div>
                <div className="paragraph">
                  Resolution-related decisions are decisions that are related to a specific draft resolution. The UNGA-DM Database incudes all resolution-related decisions. Resolution-related decisions include separate votes on parts of draft resolutions, amendments to draft resolutions, and motions that deal with a specific draft resolution. It's not always obvious which motions are resolution-related. We count motions for closure of debate on a draft resolution as resolution-related, but we don't count motions to adjourn a meeting or motions relating to an agenda item as resolution-related (even though they might have implications for one or more draft resolutions).
                </div>
              </div>
            }
          />
          <FAQComponent
            question="What country codes does the database use?"
            answer={
              <div>
                <div className="paragraph">
                  The UNGA-DM Database uses Correlates of War (COW) country codes. There are three member states whose codes change over time: Germany, China, and Yemen.
                </div>
              </div>
            }
          />
          <FAQComponent
            question="What variables should I use to merge datasets?"
            answer={
              <div>
                <div className="paragraph">
                  You should use primary and foreign keys to merge datasets. Each dataset in the UNGA-DM Database has a primary key — a variable (or a combination of variables) that uniquely identifies all observations. Some datasets also include foreign keys — variables that appear as a primary key in another table. Check the codebook or the SQL schema to see which variables are primary and foreign keys. We also have a tutorial that covers how to correctly merge the data.
                </div>
                <div className="paragraph">
                  When merging country-level data with the <span className='code'>votes</span> table, you should always use the <span className='code'>member_state_id</span> variable. Don't use the name of the country. The <span className='code'>votes</span> table includes three country name variables: the common name of the country (one per unique member state ID), the official name of the country at the time the vote was taken, and the current official name of the country. We include these country names in the <span className='code'>votes</span> table votes table for convenience (so you don't have to look up country codes when looking through the data), but you shouldn't use them for merging unless you really know what you're doing. If you do use the country names for merging, always check that a merge hasn't resulted in duplicate observations.
                </div>
              </div>
            }
          />
        </div>
      </div>
      {panelFooter}
    </div>
  )
}

const RPackagePanel = (props) => {
  return(
    <div className='panel__content'>
      <div className="block">
        <div className="title">
          R package
        </div>
        <div className="paragraph">
          The <span className="code">ungadm</span> package is an <span className="code">R</span> package for easily accessing data from the UNGA-DM Database API. The <span className="code">ungadm</span> package is a wrapper around the API that makes it easy to query data from the database. The package includes tools for selecting observations and variables. It also provides access to the codebook, so you can look up variables without leaving <span className="code">R</span>.
        </div>
        <div className="form__button-container--center">
          <Button variant="contained" startIcon={<GitHubIcon/>} href="https://www.github.com/jfjelstul/ungadm">
            View on GitHub
          </Button>
        </div>
        <div className="sub-title">
          Installation
        </div>
        <div className="paragraph">
          You can install the latest development version of the <span className="code">ungadm</span> package from GitHub:
        </div>
        <pre className="code-block">
{`# install.packages("devtools")
devtools::install_github("jfjelstul/ungadm")`}
        </pre>
        <div className="sub-title">
          Citation
        </div>
        <div className="paragraph">
          If you use the <span className="code">ungadm</span> package to access data from the UNGA-DM Database, please cite the package in addition to the working paper and the database:
        </div>
        <div className="quote">
          Joshua C. Fjelstul (2022). ungadm: An R Interface to the API for the United Nations General Assembly Decision-Making (UNGA-DM) Database. R package version 0.1.0.9000.
        </div>
        <div className="paragraph">
          The <span className="code">BibTex</span> entry for the package is:
        </div>
        <pre className="code-block">
{`@Manual{,
  title = {ungadm: An R Interface to the API for the United Nations General Assembly Decision-Making (UNGA-DM) Database},
  author = {Joshua C. Fjelstul},
  year = {2022},
  note = {R package version 0.1.0.9000},
}`}
        </pre>
        <div className="sub-title">
          Problems
        </div>
        <div className="paragraph">
          If you notice an error in the data or a bug in the <span className="code">ungadm</span> package, please report it on <a className="link" href="www.github.com/jfjelstul/ungadm/issues">GitHub</a>.
        </div>
      </div>
      <div className='divider' />
      <div className="block">
        <div id="tutorial" className="title">
          R package tutorial
        </div>
        <div className="paragraph">
          This tutorial provides a quick introduction to using the <span className="code">ungadm</span> package to query data from the UNGA-DM Database. Suppose we want to download decision-level data on all final passage recorded votes in regular sessions since the 70th session. Specifically, suppose we're interested in getting the document ID for meeting record that contains each decision so we can read the debate surrounding each decision. This data is available from the <span className="code">decisions</span> dataset. We can get exactly the data we're looking for right in <span className="code">R</span> in just a few easy steps using the <span className="code">ungadm</span> package. This package provides an <span className="code">R</span> interface to the UNGA-DM Database API, which also powers the data download tool in this web app.
        </div>
        <div className="sub-title">
          Authenticating with the API
        </div>
        <div className="paragraph">
          The first step is to authenticate with the UNGA-DM Database API. The API is password-protected. You use the same username and password as you do for this web app. To download data, we need to use the <span className="code">authenticate()</span> function to create a session. A session is an <span className="code">R</span> object that contains the credentials you need to query data from the API. You can pass this object into other functions from the <span className="code">ungadm</span> package to access data.
        </div>
        <div className="paragraph">
          We'll need to pass in your username and password to the <span className="code">authenticate()</span> function (the example code uses placeholders).
        </div>
        <pre className="code-block">
{`session <- authenticate(
  username = "USERNAME",
  password = "PASSWORD"
)`}
        </pre>
        <div className="paragraph">
          The <span className="code">session</span> object has the class <span className="code">ungadm_session</span> and stores an encrypted JSON web token (JWT) provided by the API. To access data from the API, we need to provide this token. We can do this simply by passing the <span className="code">session</span> object to any function in the <span className="code">ungadm</span> package that requires a <span className="code">session</span> argument.
        </div>
        <div className="paragraph">
          The token verifies to the API that you have provided a valid username and password. It also tells the API how long your session is valid for. Sessions automatically time out, so you don't need to close them. If you get an error from a <span className="code">ungadm</span> function that says that an API query was not successful, it's most likely because your session has timed out.
        </div>
        <div className="paragraph">
          You can check that your session is valid using <span className="code">check_authentication()</span>.
        </div>
        <div className="sub-title">
          Looking up datasets
        </div>
        <div className="paragraph">
          To download data, we'll need to specify the dataset that contains what we're interested in. In this example, we're interested in getting data from the <span className="code">decisions</span> dataset. But if we don't already know what dataset we need, or we don't know what name we need to pass to the download function, we can use the <span className="code">list_datasets()</span> function to get a list of the datasets that are available. We'll pass in our <span className="code">session</span> argument.
        </div>
        <pre className="code-block">
{`list_datasets(session = session)`}
        </pre>
        <div className="paragraph">
          The <span className="code">list_datasets()</span> function requests information via the API and returns a string vector of dataset names that we can pass into any function that requires a <span className="code">dataset</span> argument.
        </div>
        <div className="paragraph">
          If we need more information about each dataset to know which one contains the information we're interested in, we can use the <span className="code">describe_datasets()</span> function. This function returns a <span className="code">tibble</span> that includes a description of each dataset.
        </div>
        <pre className="code-block">
{`describe_datasets(session = session)`}
        </pre>
        <div className="sub-title">
          Checking the codebook
        </div>
        <div className="paragraph">
          In this example, we're interesting in getting the meeting record for each decision. We can check that the <span className="code">decisions</span> dataset includes this information using the <span className="code">list_variables()</span> function. In addition to a <span className="code">session</span> argument, this function also requires a <span className="code">dataset</span> argument, which has to be one of the values returned by <span className="code">list_datasets()</span>.
        </div>
        <pre className="code-block">
{`list_variables(
  session = session,
  dataset = "decisions"
)`}
        </pre>
        <div className="paragraph">
          Some of these variables are self-explanatory, but if we need more information, we can use <span className="code">describe_variables()</span>. This function returns the codebook for a dataset as a <span className="code">tibble</span>.
        </div>
        <pre className="code-block">
{`describe_variables(
  session = session,
  dataset = "decisions"
)`}
        </pre>
        <div className="sub-title">
          Selecting data
        </div>
        <div className="paragraph">
          Now we're ready to select the data we're interested in. In this example we're interested in all final passage recorded votes in regular sessions since the 70th session. Looking at the codeboook, we'll need to apply <span className="code">4</span> filters: one that specifies the topic of the decision (draft resolution, amendment, motion, etc.), one that specifies the mode of the decision (recorded vote, non-recorded vote, etc.), one that specifies the type of the session (regular session, etc.), and one that specifies the minimum number of the session.
        </div>
        <div className="paragraph">
          The <span className="code">download_data()</span> function has two required arguments: <span className="code">session</span> and <span className="code">dataset</span>. It also has two optional arguments: <span className="code">filters</span> and <span className="code">variables</span>. We can use the <span className="code">filters</span> argument to apply these search filters and the <span className="code">variables</span> argument to select a subset of variables.
        </div>
        <div className="paragraph">
          The <span className="code">filters</span> argument requires a list. Each element in the list specifies a filter to apply to the data. The name of each element should be the name of a variable in the specified dataset and the corresponding value should be a value or vector of values that the variable can take. The results will only include observations where the variable equals one of the provided values. For numeric variables, we can specify a range by adding a <span className="code">_min</span> or <span className="code">_max</span> suffix to the variable name. If we specify multiple filters, the results will only include observations that match all of the filters.
        </div>
        <div className="paragraph">
          We'll need to create a list with <span className="code">4</span> elements to pass into <span className="code">download_data()</span>.
        </div>
        <pre className="code-block">
{`list(
  decision_topic = "draft resolution",
  decision_mode = "recorded vote",
  session_type = "regular session",
  session_number_min = 70
)`}
        </pre>
        <div className="paragraph">
          Note that we're specifying filters for the variables <span className="code">session_type</span> and <span className="code">session_number</span> despite the fact that the <span className="code">decisions</span> dataset doesn't include these variables. They're in the <span className="code">sessions</span> dataset, but they can be merged into the <span className="code">decisions</span> based on <span className="code">session_id</span>, which is in the <span className="code">decisions</span> dataset. The API knows this, and performs the merge automatically behind the scenes.
        </div><div className="paragraph">
          We can select specific variables by passing a string vector to the <span className="code">variables</span> argument, like <span className="code">c(decision_id, meeting_record_id)</span>.
        </div>
        <div className="sub-title">
          Download data
        </div>
        <div className="paragraph">
          Now that we know how to select observations and variables, we can use <span className="code">download_data()</span> to download the data we're interested in using the API.
        </div>
        <pre className="code-block">
{`out <- download_data(
  session = session,
  dataset = "decisions",
  filters = list(
    decision_topic = "draft resolution",
    decision_mode = "recorded vote",
    session_type = "regular session",
    session_number_min = 70
  ),
  variables = c("decision_id", "meeting_record_id")
)`}
        </pre>
        <pre className="code-block">
{`Requesting data via the UNGA-DM Database API...
Response received.
Observations requested: 561.
Downloading 10000 observations every 5 seconds.
Total estimated time: 0 minutes (0 seconds).
Batch 1 of 1 complete (observations 1 to 561 of 561).
Your download is complete!

Please cite our working paper, the database, and the R package.

The working paper:

Fjelstul, Joshua C., Simon Hug, and Christopher Kilby. 2023. "Decision-Making in the United Nations General Assembly: A Comprehensive Database of Resolutions, Decisions, and Votes." Villanova School of Business Economics and Statistics Working Paper Series No. 56. URL: https://EconPapers.repec.org/RePEc:vil:papers:56.

The database:

Fjelstul, Joshua C., Simon Hug, and Christopher Kilby. 2023. The United Nations General Assembly Decision-Making (UNGA-DM) Database. Version 0.1. URL: https://www.unvotes.org.

The R package:

Joshua C. Fjelstul (2023). ungadm: An R Interface to the API for the United Nations General Assembly Decision-Making (UNGA-DM) Database. R package version 0.1.0. https://github.com/jfjelstul/ungadm.`}
        </pre>
        <div className="paragraph">
          The <span className="code">download_data()</span> function downloads the data in batches of <span className="code">10000</span> observations. The API has a rate limit, but this function automatically manages the rate limit for us. It will download <span className="code">1</span> batch approximately every <span className="code">5</span> seconds.
        </div>
        <div className="paragraph">
          The function prints useful information to the <span className="code">console</span> while the data downloads. It tells us how many observations we have requested, how many batches it will take to download the data, and approximately how long it will take. It provides an update every time a batch is downloaded and counts down to the next batch. The function returns a <span className="code">tibble</span> that we can manipulate with <span className="code">dplyr</span> and <span className="code">tidyr</span>. The function also prints the suggested citations for our working paper, the database, and the <span className="code">ungadm</span> package.
        </div>
      </div>
      {panelFooter}
    </div>
  )
}

const APIPanel = (props) => {
  return(
    <div className='panel__content'>
      API
    </div>
  )
}

const ContributorsPanel = (props) => {
  return(
    <div className='panel__content'>
      <div className="block block-centered">
        <BioComponent id="Joshua-Fjelstul" data={bios.fjelstul}/>
        <BioComponent id="Simon-Hug" data={bios.hug}/>
        <BioComponent id="Christopher-Kilby" data={bios.kilby}/>
      </div>
    </div>
  )
}

export { IntroductionPanel, DownloadPanel, SearchPanel, TutorialPanel, DocumentationPanel, FAQsPanel, RPackagePanel, APIPanel, ContributorsPanel };
