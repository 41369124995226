// React components
import React, { Component } from "react";

// Material-UI components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

export default function DataTableComponent(props) {

  const { data, headerData, width } = props;

  return (
    <div className="data-table" style={{ maxHeight: "100%" }}>
      <TableContainer style={{ maxHeight: "100%" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headerData.map((column, i) => (
                <TableCell
                  key={i}
                  className="data-table__header"
                  align="left"
                  style={{
                    borderRight: (headerData.length === i + 1) ? "none" : "1px solid #B0D4F6"
                  }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <TableRow key={i}>
                {Object.values(row).map((cell, j) => (
                  <TableCell
                    key={j}
                    align="left"
                    style={{
                      borderBottom: (data.length === i + 1) ? "none" : "1px solid #B0D4F6",
                      borderRight: (Object.values(row).length === j + 1) ? "none" : "1px solid #B0D4F6"
                    }}
                  >
                    {cell === null ? "NA" : cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
